import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, Snackbar, Alert } from '@mui/material';

function CreateUser() {
  const [nombreCliente, setNombreCliente] = useState('');
  const [usuarioTwitter, setUsuarioTwitter] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleCreateUser = () => {

    if (!nombreCliente || !usuarioTwitter) {
        setSnackbarMessage('Nombre Cliente y Usuario Twitter son requeridos');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }
  
    const newUser = {
      nombre_cliente: nombreCliente,
      usuario_twitter: usuarioTwitter
    };

    axios.post('https://opus-twitter-exclusivo.rimgsa.com/users', newUser)
      .then(response => {
        setSnackbarMessage('Usuario creado exitosamente');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        setNombreCliente('');
        setUsuarioTwitter('');
        console.log(response);
      })
      .catch(error => {
        console.error('Error al crear el usuario:', error);
        setSnackbarMessage('Error al crear el usuario');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '60vh',
        padding: '20px',
        borderRadius: '10px',
        width: '600px',
        margin: '0 auto'
      }}
    >
      <Typography variant="h4" component="div" sx={{ marginBottom: '30px' }}>
        Crear Nuevo Cliente
      </Typography>
      <TextField
        required
        id="nombre-cliente"
        label="Nombre Cliente"
        value={nombreCliente}
        onChange={(e) => setNombreCliente(e.target.value)}
        fullWidth
        sx={{ marginBottom: '20px' }}
      />
      <TextField
        required
        id="usuario-twitter"
        label="Usuario Twitter"
        value={usuarioTwitter}
        onChange={(e) => setUsuarioTwitter(e.target.value)}
        fullWidth
        sx={{ marginBottom: '20px' }}
      />
      <Button
        variant="contained"
        onClick={handleCreateUser}
        sx={{ marginBottom: '20px' }}
      >
        Crear Usuario
      </Button>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
        <Typography variant="h6" component="div" sx={{ marginBottom: '10px' }}>
          Coloca de manera correcta los datos del cliente
        </Typography>
        <Typography variant="body1" component="div" sx={{ marginBottom: '10px' }}>
          Ejemplo:
        </Typography>
        <Typography variant="body1" component="div" sx={{ marginBottom: '10px' }}>
          Nombre de cliente: Mara Lezama   ----------------   Usuario Twitter: @MaraLezama
        </Typography>
        <Typography variant="body1" component="div">
          No olvides colocar la @ antes del usuario
        </Typography>
      </Box>
    </Box>
  );
}

export default CreateUser;
