import React, { useState } from 'react';
import axios from 'axios';
import {
  Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button, Snackbar, Alert
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es'; // Importa el locale español
import useFetchUsers from './UsersTwitter';
import { Pie } from 'react-chartjs-2';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const ReportClient = () => {
  const [searchClient, setSearchClient] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tweets, setTweets] = useState([]);
  const [mentions, setMentions] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [commentsTotalTweets, setCommentsTotalTweets] = useState(0);
  const [commentsPosTweets, setCommentsPosTweets] = useState(0);
  const [commentsNeuTweets, setCommentsNeuTweets] = useState(0);
  const [commentsNegTweets, setCommentsNegTweets] = useState(0);
  const [bottonReset, setBotonReset] = useState(false);

  const { usersTwitter, userinfo } = useFetchUsers();

  const fetchResetData = () => {
    setTweets([]);
    setMentions([]);
    setStartDate(null);
    setEndDate(null);
    setSearchClient('');
    setCommentsPosTweets(0);
    setCommentsNeuTweets(0);
    setCommentsNegTweets(0);
    setCommentsTotalTweets(0);
    setBotonReset(false);
  };

  function DataGeneralTweets(tweets) {

    let total = 0;
    let pos = 0;
    let neg = 0;
    let neu = 0;
    console.log("Sacar la data general de tweets conforme el rango y cliente establecido");
    tweets.map(tweet => {
      console.log(tweet);
      total += tweet.cantidad_comments;
      pos += tweet.comments_positivos;
      neg += tweet.comments_negativos;
      neu += tweet.comments_neutros;
    })
    setCommentsTotalTweets(total);
    setCommentsPosTweets(pos);
    setCommentsNeuTweets(neu);
    setCommentsNegTweets(neg);
    setBotonReset(true);
  }

  const fetchFilteredTweetsMentions = () => {
    const params = {
      client: searchClient,
      startDate: startDate,
      endDate: endDate
    };

    if (!searchClient || !startDate || !endDate ) {
      setSnackbarMessage('Selecciona todos los filtros');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setSearchClient('');
      return;
    }
  
    axios.get('https://opus-twitter-exclusivo.rimgsa.com/tweetsReport', { params })
      .then(response => {
        setTweets(response.data);
        DataGeneralTweets(response.data);
      })
      .catch(error => {
        console.error('Error al obtener los tweets filtrados:', error);
      });

    axios.get('https://opus-twitter-exclusivo.rimgsa.com/mentionsReport', { params })
      .then(response => {
        console.log('Menciones por fecha', response.data);
        setMentions(response.data);
      })
      .catch(error => {
        console.error('Error al obtener las menciones filtradas:', error);
      });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const formatTweetDate = (dateString) => {
    return new Intl.DateTimeFormat('es-MX', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'America/Cancun'
    }).format(new Date(dateString));
  };

  const createPieData = (positives, negatives, neutrals) => {
    const total = positives + negatives + neutrals;
    return {
      labels: ['Positivos', 'Negativos', 'Neutros'],
      datasets: [
        {
          data: [positives, negatives, neutrals],
          backgroundColor: ['#32CD32', '#FF0000', '#808080'],
          datalabels: {
            formatter: (value, context) => {
              return ((value / total) * 100).toFixed(2) + '%';
            },
          },
        }
      ]
    };
  };

  const exportToPDF = () => {
    const input = document.getElementById('pdf-content');
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save(`${searchClient}_reporte.pdf`);
      });
    } else {
      console.error("Element with id 'pdf-content' not found.");
    }
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginBottom: '20px', marginTop: '20px' }}>
          <DatePicker
            label="Fecha inicio"
            value={startDate}
            onChange={setStartDate}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="Fecha fin"
            value={endDate}
            onChange={setEndDate}
            renderInput={(params) => <TextField {...params} />}
          />
          <FormControl fullWidth style={{ marginBottom: '20px' }}>
            <InputLabel id="client-select-label">Buscar por cliente</InputLabel>
            <Select
              labelId="client-select-label"
              id="client-select"
              value={searchClient}
              label="Buscar por cliente"
              onChange={(e) => setSearchClient(e.target.value)}
            >
              {usersTwitter.map(client => (
                <MenuItem key={client} value={client}>{client}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', gap: '10px' }}>
          <Button
            variant="contained"
            onClick={fetchFilteredTweetsMentions}
            style={{ marginLeft: '10px', marginTop: '20px' }}
          >
            Filtrar
          </Button>
          <Button
            variant="contained"
            onClick={fetchResetData}
            style={{ marginLeft: '10px', marginTop: '20px' }}
          >
            Resetear
          </Button>
          <Button
            variant="contained"
            onClick={exportToPDF}
            style={{ marginLeft: '10px', marginTop: '20px' }}
          >
            Exportar PDF
          </Button>
          </Box>
          </Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </LocalizationProvider>
      <Box id="pdf-content" sx={{ padding: '20px' }}>

      <Box sx={{ padding: '20px' }}>
          {mentions.map(mention => (
            <Box key={mention.id_menciones_general} sx={{ display: 'flex', marginBottom: '20px', borderBottom: '1px solid #ccc', paddingBottom: '20px' }}>
              <Box sx={{ flex: 1, marginRight: '20px' }}>
                <Typography variant="h6" sx={{ textAlign: 'justify' }}>Menciones</Typography>
              </Box>
              <Box sx={{ flex: 1, marginLeft: '20px' }}>
                <Typography>Total de menciones: {mention.total}</Typography>
                <Typography>Menciones positivas: {mention.positivas}</Typography>
                <Typography>Menciones negativas: {mention.negativas}</Typography>
                <Typography>Menciones neutras: {mention.neutras}</Typography>
              </Box>
              <Box sx={{ flex: 1, marginTop: '20px' }}>
                <Pie data={createPieData(parseInt(mention.positivas), parseInt(mention.negativas), parseInt(mention.neutras))}
                options={{ plugins: { datalabels: { color: '#000000', offset: -10, borderWidth: 2, borderRadius: 4, backgroundColor: '#ffffff', font: { weight: 'bold' } }}}}
                />
              </Box>
            </Box>
          ))}
        </Box>



      { bottonReset && (
        <Box sx={{ padding: '20px'}}>
              <Box sx={{ display: 'flex', marginBottom: '50px', borderBottom: '1px solid #ccc', paddingBottom: '20px' }}>
                <Box sx={{ flex: 1, marginRight: '20px' }}>
                  <Typography variant="h6" sx={{ textAlign: 'justify', marginBottom: '30px' }}>Tweet General:</Typography>
                </Box>
                <Box sx={{ flex: 1, marginLeft: '20px', marginTop: '60px' }}>
                  <Typography>Comentarios totales: {commentsTotalTweets}</Typography>
                  <Typography>Comentarios positivos: {commentsPosTweets}</Typography>
                  <Typography>Comentarios negativos: {commentsNegTweets}</Typography>
                  <Typography>Comentarios neutros: {commentsNeuTweets}</Typography>
                </Box>
                <Box sx={{ flex: 1, marginTop: '20px' }}>
                <Pie
                    data={createPieData(commentsPosTweets, commentsNegTweets, commentsNeuTweets)}
                    options={{ plugins: { datalabels: { color: '#000000', offset: -10, borderWidth: 2, borderRadius: 4, backgroundColor: '#ffffff', font: { weight: 'bold' } }}}}
                  />
                </Box>
              </Box>

        </Box>

      )}

        <Box sx={{ padding: '20px' }}>
          {tweets.map(tweet => (
            <Box key={tweet.id_tweet_user} sx={{ display: 'flex', marginBottom: '50px', borderBottom: '1px solid #ccc', paddingBottom: '20px' }}>
              <Box sx={{ flex: 1, marginRight: '20px' }}>
                <Typography variant="h6" sx={{ textAlign: 'justify', marginBottom: '30px' }}>Tweet:</Typography>
                <Typography sx={{ textAlign: 'justify' }}>{tweet.text}</Typography>
              </Box>
              <Box sx={{ flex: 1, marginLeft: '20px', marginTop: '60px' }}>
                <Typography>Fecha: {formatTweetDate(tweet.fecha_tweet)}</Typography>
                <Typography>Likes: {tweet.cantidad_likes}</Typography>
                <Typography>Reposts: {tweet.cantidad_repost}</Typography>
                <Typography>Comentarios: {tweet.cantidad_comments}</Typography>
                <Typography>Comentarios Positivos: {tweet.comments_positivos}</Typography>
                <Typography>Comentarios Negativos: {tweet.comments_negativos}</Typography>
                <Typography>Comentarios Neutros: {tweet.comments_neutros}</Typography>
              </Box>
              <Box sx={{ flex: 1, marginTop: '20px' }}>
              <Pie
                  data={createPieData(tweet.comments_positivos, tweet.comments_negativos, tweet.comments_neutros)}
                  options={{ plugins: { datalabels: { color: '#000000', offset: -10, borderWidth: 2, borderRadius: 4, backgroundColor: '#ffffff', font: { weight: 'bold' } }}}}
                />
              </Box>
            </Box>
          ))}
        </Box>

      </Box>
    </div>
  );
}

export default ReportClient;
