import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Hook personalizado para obtener los usuarios de Twitter
const useFetchUsers = () => {
  const [usersTwitter, setUsersTwitter] = useState([]);
  const [userinfo, setUserinfo] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://opus-twitter-exclusivo.rimgsa.com/clients');
        const user_info = response.data;
        setUserinfo(user_info);
        const twitterUsers = response.data.map(user => user.usuario_twitter);
        setUsersTwitter(twitterUsers);
      } catch (error) {
        console.error('Error al obtener los usuarios de Twitter:', error);
      }
    };

    fetchUsers();
  }, []);

  return { usersTwitter, userinfo };
};

export default useFetchUsers;


