import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, Modal, Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Snackbar, Alert
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import useFetchUsers from './UsersTwitter';

function TweetMetrics() {
  const [tweets, setTweets] = useState([]);
  const [openTextModal, setOpenTextModal] = useState(false);
  const [selectedTweet, setSelectedTweet] = useState(null);
  const [openCommentsModal, setOpenCommentsModal] = useState(false);
  const [searchClient, setSearchClient] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const { usersTwitter, userinfo } = useFetchUsers();

  function formatTweetDate(dateString) {
    return new Intl.DateTimeFormat('es-MX', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      timeZone: 'America/Cancun' 
    }).format(new Date(dateString));
  }

  function exportToCSV() {
    const csvRows = [];
    const headers = ['Autor', 'Texto', 'Likes', 'Comentarios', 'Reposts', 'Fecha', 'Comentarios Post', 'Positivos', 'Negativos', 'Neutros', 'URL Tweet'];
    csvRows.push(headers.join(','));

    tweets.forEach(tweet => {
      const row = [
        `"${tweet.user_tweet}"`,
        `"${tweet.text.replace(/"/g, '""')}"`, 
        tweet.cantidad_likes,
        tweet.cantidad_comments,
        tweet.cantidad_repost,
        `"${formatTweetDate(tweet.fecha_tweet)}"`, 
        `"${tweet.comments_post.replace(/"/g, '""')}"`, 
        tweet.comments_positivos,
        tweet.comments_negativos,
        tweet.comments_neutros,
        tweet.link_tweet
      ];
      csvRows.push(row.join(','));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'tweets.csv';
    link.click();
  }

  function fetchFilteredTweets() {

    if (!searchClient) {
      setSnackbarMessage('Selecciona un usuario');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setSearchClient('');
      return;
    }

    console.log(searchClient);
    const params = {
      client: searchClient,
    };


  
    axios.get('https://opus-twitter-exclusivo.rimgsa.com/tweetsMetrics', { params })
      .then(response => {
        console.log('filtro por usuario->', response.data);
        const sortedTweets = response.data.sort((a, b) => new Date(b.fecha_tweet) - new Date(a.fecha_tweet));
        setTweets(sortedTweets);
      })
      .catch(error => {
        console.error('Error al obtener los tweets filtrados:', error);
      });
  }

  function fetchResetTweets() {

    fetchUserTweetsMetricsReset();
    setSearchClient(null);

  }

  function handleUpdateTweetsMetrics() {
    fetchUserTweetsMetricsReset();
    setSnackbarMessage('Información actualizada!');
    setSnackbarSeverity('success');
    setOpenSnackbar(true);
  }

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    axios.get('https://opus-twitter-exclusivo.rimgsa.com/tweetsMetrics')
      .then(response => {
        const sortedTweets = response.data.sort((a, b) => new Date(b.fecha_tweet) - new Date(a.fecha_tweet));
        setTweets(sortedTweets);
      })
      .catch(error => {
        console.error('Error al obtener los tweets:', error);
      });
  }, []);

  const fetchUserTweetsMetricsReset = () => {

    axios.get('https://opus-twitter-exclusivo.rimgsa.com/tweetsMetrics')
        .then(response => {
            const sortedTweets = response.data.sort((a, b) => new Date(b.fecha_tweet) - new Date(a.fecha_tweet));
            setTweets(sortedTweets);
        })
        .catch(error => {
            console.error('Error al obtener los tweets:', error);
        });
  }

  const handleOpenTextModal = (tweet) => {
    setSelectedTweet(tweet);
    setOpenTextModal(true);
  };

  const handleCloseTextModal = () => {
    setOpenTextModal(false);
  };

  const handleOpenCommentsModal = (tweet) => {
    setSelectedTweet(tweet);
    setOpenCommentsModal(true);
  };

  const handleCloseCommentsModal = () => {
    setOpenCommentsModal(false);
  };

  const getSentimentStyle = (sentiment) => {
    if (sentiment.includes('POS')) {
      return { backgroundColor: '#32CD32' }; 
    } else if (sentiment.includes('NEG')) {
      return { backgroundColor: '#FF0000' }; 
    } else if (sentiment.includes('NEU')) {
      return { backgroundColor: '#808080' }; 
    }
    return {}; 
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '80vw',
    maxHeight: '70vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const parseComments = (comments) => {
    if (!comments || comments === "[]") return [];
    return comments.match(/('[^']+'|"[^"]+")/g).map(comment => comment.slice(1, -1));
  };

  const getTweetStyle = (tweet) => {
    const user = userinfo.find(user => user.usuario_twitter === tweet.user_tweet);
    if (user) {
      const meetsReactions = tweet.cantidad_likes > user.meta_reacciones;
      const meetsComments = tweet.cantidad_comments > user.meta_comentarios;
      const meetsReposts = tweet.cantidad_repost > user.meta_repost;

      if (meetsReactions && meetsComments && meetsReposts) {
        return null; // Return null to filter out tweets that meet the metrics
      }

      const tweetDate = new Date(tweet.fecha_tweet);
      const currentDate = new Date();
      const diffInHours = Math.abs(currentDate - tweetDate) / 36e5; // Difference in hours

      if (diffInHours <= 1) {
        return { backgroundColor: '#76F265' }; // Green for <= 1 hour
      } else if (diffInHours > 1 && diffInHours <= 3) {
        return { backgroundColor: '#FCF167' }; // Yellow for > 1 and <= 3 hours
      } else {
        return { backgroundColor: '#FB8181' }; // Red for > 3 hours
      }
    }
    return {};
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginBottom: '20px', marginTop: '20px' }}>
          <FormControl fullWidth style={{ minWidth: '200px' }}>
            <InputLabel id="client-select-label">Buscar por cliente</InputLabel>
            <Select
              labelId="client-select-label"
              id="client-select"
              value={searchClient}
              label="Buscar por cliente"
              onChange={(e) => setSearchClient(e.target.value)}
            >
              {usersTwitter.map(client => (
                <MenuItem key={client} value={client}>{client}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Button
              variant="contained"
              onClick={fetchFilteredTweets}
              style={{ marginLeft: '10px', marginTop: '20px' }}
            >
              Filtrar
            </Button>
            <Button
              variant="contained"
              onClick={fetchResetTweets}
              style={{ marginLeft: '10px', marginTop: '20px' }}
            >
              Resetear
            </Button>
            <Button
              variant="contained"
              onClick={exportToCSV}
              style={{ marginLeft: '10px', marginTop: '20px' }}
            >
              Exportar CSV
            </Button>
            <Button
              variant="contained"
              onClick={handleUpdateTweetsMetrics}
              style={{ marginLeft: '10px', marginTop: '20px' }}
            >
              Actualizar
            </Button>
            
          </Box>
        </Box>
      </LocalizationProvider>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Autor</TableCell>
              <TableCell>Ver Texto</TableCell>
              <TableCell>Likes</TableCell>
              <TableCell>Comentarios</TableCell>
              <TableCell>Reposts</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Ver Comentarios</TableCell>
              <TableCell>Positivos</TableCell>
              <TableCell>Negativos</TableCell>
              <TableCell>Neutros</TableCell>
              <TableCell>URL Tweet</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tweets.filter(tweet => {
              const user = userinfo.find(user => user.usuario_twitter === tweet.user_tweet);
              if (user) {
                const meetsReactions = tweet.cantidad_likes >= user.meta_reacciones;
                const meetsComments = tweet.cantidad_comments >= user.meta_comentarios;
                const meetsReposts = tweet.cantidad_repost >= user.meta_repost;
                return !(meetsReactions && meetsComments && meetsReposts);
              }
              return true;
            }).map((tweet) => (
              <TableRow key={tweet.id_tweet_user} style={getTweetStyle(tweet)}>
                <TableCell>{tweet.user_tweet}</TableCell>
                <TableCell><Button onClick={() => handleOpenTextModal(tweet)}>Ver Texto</Button></TableCell>
                <TableCell>{tweet.cantidad_likes}</TableCell>
                <TableCell>{tweet.cantidad_comments}</TableCell>
                <TableCell>{tweet.cantidad_repost}</TableCell>
                <TableCell>{formatTweetDate(tweet.fecha_tweet)}</TableCell>
                <TableCell>
                  <Button 
                    onClick={() => handleOpenCommentsModal(tweet)}
                    disabled={!tweet.comments_post || tweet.comments_post === "[]"}
                  >
                    Ver Comentarios
                  </Button>
                </TableCell>
                <TableCell>{tweet.comments_positivos}</TableCell>
                <TableCell>{tweet.comments_negativos}</TableCell>
                <TableCell>{tweet.comments_neutros}</TableCell>
                <TableCell>
                  <a href={tweet.link_tweet} target="_blank" rel="noopener noreferrer">Ver tweet</a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
        open={openTextModal}
        onClose={handleCloseTextModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Texto del Tweet
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {selectedTweet?.text}
          </Typography>
        </Box>
      </Modal>

      <Modal
        open={openCommentsModal}
        onClose={handleCloseCommentsModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Comentarios
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: '50vh', overflow: 'auto' }}>
            <Table sx={{ minWidth: 500 }} aria-label="comments table">
              <TableHead>
                <TableRow>
                  <TableCell>Comentario</TableCell>
                  <TableCell>Autor</TableCell>
                  <TableCell>Sentimiento</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedTweet && parseComments(selectedTweet.comments_post).map((comment, index) => (
                  <TableRow key={index}>
                    <TableCell>{comment}</TableCell>
                    <TableCell>{selectedTweet?.comment_authors.split(',')[index]}</TableCell>
                    <TableCell style={getSentimentStyle(selectedTweet.comments_sentimientos.split(',')[index])}>
                      {selectedTweet.comments_sentimientos.split(',')[index]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}

export default TweetMetrics;
